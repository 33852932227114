
import { computed, defineComponent, onMounted, ref } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Toolbar from "primevue/toolbar";
import { useStore } from "vuex";
import { ProductGetters } from "@/store/product/getters";
import { ProductActions } from "@/store/product/actions";
import { IDataTableEvent } from "@/store/types";
import { FilterMatchMode } from "primevue/api";
import { CategoryActions } from "@/store/category/actions";
import { CategoryGetters } from "@/store/category/getters";
import { productTypes } from "@/store/product/state";

export default defineComponent({
  name: "Stock",
  components: {
    DataTable,
    Column,
    Toolbar,
  },
  setup() {
    onMounted(async () => {
      lazyParams.value.rows = dt.value.rows;
      await loadLazyData();

      await store.dispatch(CategoryActions.FETCH_ALL);
      categories.value = await store.getters[CategoryGetters.GET_ALL];
    });

    const loading = ref(false);
    const store = useStore();

    const categories = ref([]);

    const dt = ref();

    const filters = ref({
      name: { value: "", matchMode: FilterMatchMode.CONTAINS },
      categoryId: { value: "", matchMode: FilterMatchMode.CONTAINS },
      type: { value: "", matchMode: FilterMatchMode.CONTAINS },
      priceIn: { value: "", matchMode: FilterMatchMode.CONTAINS },
      priceRetail: { value: "", matchMode: FilterMatchMode.CONTAINS },
      totalCost: { value: "", matchMode: FilterMatchMode.CONTAINS },
    });

    const lazyParams = ref<IDataTableEvent>({
      page: 0,
      sortField: null,
      sortOrder: 1,
      first: 0,
      rows: 0,
      filters: filters.value,
    });

    const dataProvider = computed(
      () => store.getters[ProductGetters.GET_DATA_PROVIDER]
    );

    async function loadLazyData() {
      loading.value = true;

      await store
        .dispatch(ProductActions.FETCH_LAZY_DATA, lazyParams.value)
        .then(
          () => {
            loading.value = false;
          },
          (error) => {
            console.error(error);
          }
        );
    }

    async function onSort(event: IDataTableEvent) {
      lazyParams.value = event;
      await loadLazyData();
    }

    async function onPage(event: IDataTableEvent) {
      lazyParams.value = event;
      await loadLazyData();
    }

    async function onFilter() {
      lazyParams.value.filters = filters.value;
      await loadLazyData();
    }

    function exportCSV() {
      dt.value.exportCSV();
    }

    return {
      dt,
      loading,
      dataProvider,
      filters,
      onSort,
      onPage,
      onFilter,
      exportCSV,
      categories,
      productTypes,
    };
  },
});
